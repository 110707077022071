'use client'

import { QuestionCircle16, X24 } from '@vinted/monochrome-icons'
import {
  BottomSheet,
  Button,
  Cell,
  Dialog,
  Divider,
  Icon,
  Image,
  Navigation,
  PromoBanner,
  Spacer,
  Text,
} from '@vinted/web-ui'
import { useState } from 'react'

import { useBreakpoint } from '@marketplace-web/shared/breakpoints'
import { useTracking } from '@marketplace-web/shared/event-tracker'
import { useTranslate } from '@marketplace-web/shared/i18n'
import { useAsset } from '@marketplace-web/shared/ui-helpers'

import { clickClosetPromotionEvent, clickEvent } from '_libs/common/event-tracker/events'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { ContentSource } from 'constants/tracking/content-sources'
import { ClosetModel, VasEntryPointModel } from 'types/models'

import { getPromotedClosetContentSource } from '../../utils/contentSource'
import Closet from '../Closet'
import ClosetPromotionCheckout from './ClosetPromotionCheckout'

type Props = {
  wide?: boolean
  banner?: VasEntryPointModel
  closet: ClosetModel
  position: number
  contentSource?: ContentSource
  homepageSessionId?: string
}

const ClosetPromotion = ({
  wide = false,
  banner,
  closet,
  position,
  contentSource,
  homepageSessionId,
}: Props) => {
  const { track } = useTracking()
  const breakpoints = useBreakpoint()
  const translate = useTranslate('closet_promotion.listing')

  const asset = useAsset('assets/closet-promotion')

  const [isHelpVisible, setIsHelpVisible] = useState(false)
  const [isPrecheckoutOpen, setIsPrecheckoutOpen] = useState(false)

  function handlePrecheckoutOpen() {
    setIsPrecheckoutOpen(true)
  }

  function handlePrecheckoutClose() {
    setIsPrecheckoutOpen(false)
  }

  function handleCheckoutButtonClick() {
    handlePrecheckoutOpen()
    track(
      clickEvent({
        target: ClickableElement.PromoteCloset,
      }),
    )
  }

  function handleHelpClick() {
    setIsHelpVisible(true)

    track(
      clickClosetPromotionEvent({
        ownerId: closet.user.id,
        contentSource: getPromotedClosetContentSource(contentSource),
        target: ClickableElement.ClosetPromotionHelp,
      }),
    )
  }

  function handleHelpClose() {
    setIsHelpVisible(false)
  }

  function renderClosetPromotionBanner() {
    if (!banner || !closet.showBanner) return null

    return (
      <>
        <Spacer />
        <PromoBanner
          title={banner.title}
          body={banner.subtitle}
          icon={<Image src={asset('diamond-star-32.svg')} />}
          styling={PromoBanner.Styling.Tight}
          clickable
          actionText={banner.buttonTitle ?? ''}
          onClick={handleCheckoutButtonClick}
          testId="closet-promotion-banner"
        />
      </>
    )
  }

  function renderHelpPopup() {
    if (breakpoints.phones) {
      return (
        <BottomSheet
          isVisible={isHelpVisible}
          onClose={handleHelpClose}
          title={translate('help.title')}
          closeButtonEnabled
          closeOnOverlayClick
        >
          <Cell styling={Cell.Styling.Wide} body={translate('help.body')} />
        </BottomSheet>
      )
    }

    return (
      <Dialog show={isHelpVisible} defaultCallback={handleHelpClose} closeOnOverlay>
        <Navigation
          body={
            <Cell>
              <Text text={translate('help.title')} type={Text.Type.Title} as="span" />
            </Cell>
          }
          right={
            <Button
              styling={Button.Styling.Flat}
              onClick={handleHelpClose}
              iconName={X24}
              theme="amplified"
              inline
              testId="closet-promotion--help-close-button"
            />
          }
        />
        <Divider />
        <Cell styling={Cell.Styling.Wide} body={translate('help.body')} />
      </Dialog>
    )
  }

  function renderHelp() {
    return (
      <>
        <div className="u-flexbox u-align-items-center u-ui-padding-left-medium">
          <Text type={Text.Type.Subtitle} as="span">
            {translate('title')}
          </Text>
          <Button
            styling={Button.Styling.Flat}
            iconName={QuestionCircle16}
            iconColor={Icon.Color.GreyscaleLevel2}
            size={Button.Size.Medium}
            inline
            onClick={handleHelpClick}
            testId="closet-promotion--help-button"
            aria={{ 'aria-label': translate('help.a11y') }}
          />
        </div>
        {renderHelpPopup()}
      </>
    )
  }

  return (
    <>
      <Closet
        {...closet}
        wide={wide}
        position={position}
        contentSource={contentSource}
        homepageSessionId={homepageSessionId}
      />
      {renderHelp()}
      {renderClosetPromotionBanner()}
      {!!banner && closet.showBanner && (
        <ClosetPromotionCheckout
          isOpen={isPrecheckoutOpen}
          handlePrecheckoutClose={handlePrecheckoutClose}
        />
      )}
    </>
  )
}

export default ClosetPromotion
